@import "../globals.scss";

.container {
  display: flex;
  justify-content: space-between;
  gap: 50px;

  @include phone {
    text-align: center;
    flex-direction: column;
    padding: 0 24px;
  }
}

.left {
  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    max-width: 409px;

    @include phone {
      font-size: 32px;
    }
  }
}

.right {
  display: flex;
  flex-direction: column;
  max-width: 358px;
  flex: 1;
  margin-right: 100px;
  gap: 26px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  input {
    border: 1px solid white;
    // border: 2px solid #6B737A;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 14px;
    background-color: transparent;
    font-size: 14px;
    line-height: 22px;
    color: white;
    width: 100%;

    &::placeholder {
      color: white;
    }

    &:focus {
      outline: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }

  .flex {
    display: flex;
    width: 100%;
    max-width: 358px;
    align-items: center;
    input {
      width: 100%;
    }
    input:first-child {
      margin-right: 15px;
    }

    @include phone {
      max-width: 100%;
    }
  }

  .password {
    position: relative;

    input {
      width: 100%;
    }

    .eye {
      position: absolute;
      right: 10px;
      top: 20%;
    }
  }

  p {
    font-size: 14px;
    margin-left: auto;
    cursor: pointer;
  }
  p:hover {
    text-decoration: underline;
  }
  button {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    padding: 12px 14px;
    border-radius: 7px;
    border: none;
    transition: 0.2s;
    background-color: #259f6c;

    &:hover {
      background-color: #30ad53;
    }

    @include phone {
      font-size: 16px;
      padding: 10px 14px;
    }
  }
}

.hr {
  display: flex;
  gap: 27px;
  align-items: center;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  span {
    flex: 1;
    height: 1px;
    background-color: white;
  }
}

.oauth {
  display: flex;
  flex-direction: column;
  //   @include Gap(20px);
  gap: 20px;
  button > span {
    width: 25px !important;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 40px;
    gap: 23px;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    border-radius: 10px;
    border: none;

    @include phone {
      font-size: 14px;
      padding: 10px 20px;
    }
  }

  .facebook {
    background: #1877f2;
    color: #ffffff;
  }

  .apple {
    background: #000000;
    color: #ffffff;
  }

  .google {
    background: #fff;
    color: #757575;
    border: 1px solid #000000;
  }
}

.tag {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  // color: #434343;
  max-width: 350px;
  margin: 19px 0 30px;
}

.sign {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  max-width: 386px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  span:last-child {
    color: #6be6ac;
    cursor: pointer;
  }

  @include phone {
    font-size: 14px;
  }
}

.left {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: space-between;
}

.bottom {
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
    max-width: 450px;
  }
}

.wallets {
  display: flex;
  gap: 40px;
  //   @include Gap(40px);
  @include phone {
    justify-content: space-around;
  }
  div:hover {
    cursor: pointer;
  }
  img {
    width: 40px !important;
    // height: 40px !important;
    object-fit: cover;
  }
  button {
    display: flex;
    align-items: center;
    padding: 10px 40px;
    gap: 23px;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    border-radius: 10px;
    border: none;

    @include phone {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  .metamask {
    background: #000;
    color: #fff;
    border: 1px solid #000000;
  }
}
